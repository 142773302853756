<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
export default {
  data() {
    return {
      loadingTrue: true,
      color: "#00A9A0",
      size: "18px"

    };
  },
  components: {PulseLoader},
};
</script>

<style>
#loader{
  display: none;
  text-align: center;
}
.Dashboard-View {
  display: inline-block;
  min-width: 100%;
  margin-right: 30px;
}
</style>

<template>
  <section class="Dashboard-View">
    <router-view/>
    <div class="clearfix"></div>
  </section>
</template>
